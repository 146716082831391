// import React, { useState } from 'react';
import '../App.css';

function OptionRadio({checked, setChecked, disabled, name, dataKey}) {
  // const [checked, setChecked] = useState(disabled.length > 0 ? true : false);
  return (
    <div className={`setting-option${disabled}`}
          onClick={(e) => {
            if (e.target.tagName === 'A') {
              return;
            }
            e.stopPropagation();
            e.preventDefault();
            if (disabled.length > 0) {
              return;
            }
            if (checked) {
              setChecked(false);
            } else {
              setChecked(true);
            }
          }}>
            <div style={{ backgroundImage: checked ? `url("https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/new_cookies_checkbox_chosen.svg")` : `url("https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/images/new_cookies_checkbox.svg")`}}
            className={`option-radio${checked ? ' selected': ''}`} data-key={`${dataKey}`}>
              <div></div>
            </div>
            <p className={`regular${checked ? ' selected': ''}`} dangerouslySetInnerHTML={{ __html: name }}></p>
      </div>
  );
}

export default OptionRadio;