import { Link } from 'react-router-dom';
import '../App.css';

function TermsPage() {
  return (
    <div className="privacy layout-column center-center">
      <div className="privacy-terms">
        <Link to='https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/documents/Terms+of+use.pdf' target="_blank" className="pdf-link bold pink layout-row">Download pdf</Link>
        <div className="main-title">
          <h1 className="header-2 bold">BEAUTIFUL ESSENTIALS TERMS OF USE</h1>
        </div>
        <div className="page-body">
          <div className="section">
            <p className="header-2">
              <b>IMPORTANT:</b> BY USING THE SERVICES (DEFINED BELOW) YOU ACCEPT THE FOLLOWING TERMS AND CONDITIONS. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS SET FORTH HEREIN PLEASE DO NOT USE THE SERVICES.
            </p>
          </div>
          <div className="section">
            <ol className="terms-list">
              <li>
                <p className="header-2 bold">
                  Acceptance of Terms
                </p>
                <p className="regular">
                  The following instrument consists of the terms and conditions governing your, a user accessing, using or opening an account on the Services (together, "<span className="bold">you</span>" or "<span className="bold">user</span>"), access to and
                  use of the Photomyne Ltd.'s ("<span className="bold">Company</span>" or "<span className="bold">we</span>") Beautiful Essentials applications (the "<span className="bold">Services</span>"). These Terms of Service together with the Privacy Policy
                  available at the following URL: <Link to='/privacy-policy' target="_blank">beautiful-essentials.app/privacy-policy</Link> (the "<span className="bold">Terms</span>") constitute a binding agreement between you and Company. By accessing or using the
                  Services in any way or manner you agree to abide by, and be bound, by these Terms.
                </p>

                <p className="regular">
                  ARBITRATION NOTICE. THIS AGREEMENT CONTAINS AN ARBITRATION CLAUSE IN SECTION 11 BELOW. EXCEPT FOR CERTAIN TYPES OF DISPUTES, AS EXPLAINED IN SECTION 11, USERS FROM THE U.S.A. AND COMPANY AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED
                  BY MANDATORY BINDING INDIVIDUAL ARBITRATION, AND USERS FROM THE U.S.A. AND COMPANY WAIVE ANY RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
                </p>
                <p className="regular">
                  We may unilaterally change or add to the terms of these Terms at any time. In the event of a material change, we may notify you via email or by means of a prominent notice on the Services. You should check our Services
                  periodically and review changes to the Terms at the following URL: <Link to='/terms-of-use'>beautiful-essentials.app/terms-of-use</Link>. By continuing to use the Services following such modifications, you agree to be bound by such
                  modifications.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  License
                </p>
                <p className="regular">
                  Subject to the terms and conditions set forth herein, Company hereby grants you, and you accept, a personal, non-exclusive, non-transferable, non-sublicensable, revocable, limited license to access and make personal use of the
                  Services, only according to the terms of these Terms. The license may be suspended or terminated by Company at any time.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Limitations of Use
                </p>
                <p className="regular">
                  Except as specifically permitted herein, you agree not to (i) sell, license (or sub-license), lease, assign, transfer, pledge, or share your account or any of your rights under these Terms with/to any third party; (iii) use, or
                  encourage, promote, facilitate or instruct others to use the Services for any illegal, harmful or offensive use; (iv) transmit or upload any viruses, spyware or other harmful, infringing, illegal, disruptive or destructive content,
                  messages or files; (v) perform any act that destabilizes, interrupts or encumbers the Services or their servers or use “load testers”, that enable sending more request messages to the servers of the Services, in a given period of
                  time, than a human can reasonably send in that time period by using the Services; (vi) create false personas, multiple identities, set up an account on behalf of someone other than yourself or create another account after we have
                  already disabled your account; (vii) penetrate or circumvent or attempt to penetrate or circumvent any technical restrictions or limitations included in the Services or its servers; or (viii) use the Services from any jurisdiction
                  from which such use is illegal or requires Company to obtain any license or governmental permit. You are solely responsible for obtaining, paying for, repairing and maintaining all the equipment, software, hardware and services
                  required for getting access to and using the Services.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Ownership of Proprietary Rights
                </p>
                <p className="regular">
                  The Services, including without limitation any underlying data, software, algorithms, technology, design, layout, logos, tradenames, text, images, sound, music, videos, Feedback (defined below), "look and feel" and features and any
                  enhancements, improvements and derivatives thereof and all Intellectual Property Rights related thereto ("<span className="bold">Company IPR"</span>) are the property of Company and/or its licensors who retain all right, title and
                  interest in connection therewith.
                </p>

                <p className="regular">
                  No transfer or grant of any rights by Company is made or is to be implied by any provision of these Terms or by any other provision contained in the Services with respect to the Company IPR or otherwise, except for the limited license
                  set forth in Section 2 above.
                </p>

                <p className="regular">
                  We appreciate hearing from our users and welcome your comments regarding the Services. Notwithstanding anything to the contrary herein, please be advised, however, that if you send us ideas, suggestions, inventions, or materials
                  regarding the Services ("<span className="bold">Feedback</span>"), we shall: (i) own all right, title and interest in and to the Feedback without any restriction; (ii) not be subject to any obligation of confidentiality; and (iii) be
                  entitled to unrestricted use of the Feedback for any purpose whatsoever, without compensation or credit to you or any other person.
                </p>

                <p className="regular">
                  <span className="bold">"Intellectual Property Rights"</span> means worldwide, whether registered or not (a) rights associated with works of authorship, designs, mask works and photography including copyrights; (b) trademarks, service
                  marks, domain names, logos, trade names, trade dress, the right to publicity and goodwill rights; (c) patents, patent applications and industrial designs; (d) trade secrets; (e) rights analogous to those set forth herein and any other
                  proprietary rights relating to intangible property; (f) divisions, continuations, renewals, reissues and extensions of the foregoing (as applicable) now existing or hereafter filed, issued, or acquired.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  User Warranties and Representations
                </p>
                <p className="regular">
                  You represent and warrant to Company that your use of the Services shall comply with all applicable laws, regulations, licenses and permits.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Privacy
                </p>
                <p className="regular">
                  Company's privacy practices are governed by Company’s privacy policy, the most updated copy of which can be found at: <Link to='/privacy-policy' target="_blank">beautiful-essentials.app/privacy-policy</Link> ("<span className="bold">Privacy
                    Policy</span>").
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Disclaimer
                </p>
                <p className="regular">
                  IF YOU CHOOSE TO USE THE SERVICES YOU DO SO VOLUNTARILY AND AT YOUR SOLE DISCRETION.
                </p>

                <p className="regular">
                  COMPANY DOES NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
                </p>

                <p className="regular">
                  COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE COMPANY IPR OR SERVICES INCLUDING WITHOUT LIMITATION THEIR CORRECTNESS, COMPLETENESS, ACCURACY, RELIABILITY, AVAILABILITY OR
                  OTHERWISE. YOU ARE RESPONSIBLE FOR TAKING ALL PRECAUTIONS NECESSARY OR ADVISABLE TO PROTECT YOU AGAINST ANY DAMAGE OR LOSS THAT MAY ARISE BY VIRTUE OF YOUR USE OF OR RELIANCE UPON THE SERVICES.
                </p>

                <p className="regular">
                  COMPANY SHALL NOT HAVE ANY LIABILITY OR RESPONSIBILITY FOR LINKS TO THIRD PARTY CONTENT OR SERVICES. YOU USE SUCH AT YOUR OWN RISK AND SUBJECT TO THE THIRD PARTY TERMS THAT APPLY THERETO.
                </p>

                <p className="regular">
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN IMPLIED WARRANTIES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Limitation of Liability
                </p>
                <p className="regular">
                  TO THE MAXIMUM PERMITTED UNDER LAW, UNDER NO CIRCUMSTANCES WHATSOEVER WILL COMPANY AND ITS AFFILIATES, PARTNERS, OFFICERS, DIRECTORS, EMPLOYEES, SHAREHOLDERS, AGENTS, LICENSORS, SUBCONTRACTS AND SUPPLIERS BE RESPONSIBLE OR LIABLE TO
                  YOU OR TO ANY OTHER ENTITY, FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, UNDER ANY LEGAL THEORY, WHETHER CONTRACT, TORT OR OTHERWISE INCLUDING WITHOUT LIMITATION ANY LOST PROFITS AND LOST BUSINESS
                  OPPORTUNITIES, BUSINESS INTERUPTION, LOSS OF INCOME, LOSS OF DATA OR OTHER INTANGIBLE LOSSES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WITHOUT DEROGATING FROM THE FOREGOING, IF DESPITE THE FOREGOING COMPANY WILL BE FOUND
                  LIABLE OR RESPONSIBLE BY A COMPETENT AUTHORITY, UNDER ANY LEGAL THEORY, COMPANY’S AGGREGATE LIABILITY SHALL NOT EXCEED $100.
                </p>

                <p className="regular">
                  SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Indemnification
                </p>
                <p className="regular">
                  You agree to defend, indemnify and hold harmless Company and anyone on its behalf, including but not limited to, all of its owners, managers, officers, affiliates, employees, licensors and suppliers harmless against any losses,
                  expenses, costs, claims, damages (including attorneys' fees, expert fees' and other costs of litigation) and liability arising from, incurred as a result of, or in any manner related to: (i) your use of the Services; (ii) any breach
                  of these Terms.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Termination
                </p>
                <p className="regular">
                  We may terminate your right to access or use the Services immediately, at our sole discretion, without notice. Upon termination or expiration you shall immediately cease using the Services and the following Sections shall survive:
                  1,3, 4, 5, and 7-12.
                </p>
              </li>
              <li>
                <p className="header-2 bold">
                  Termination
                </p>
                <p className="regular">
                  We may terminate your right to access or use the Services immediately, at our sole discretion, without notice. Upon termination or expiration you shall immediately cease using the Services and the following Sections shall survive:
                  1,3, 4, 5, and 7-12.
                </p>
              </li>
              <li>
                <h3 className="header-2 bold">
                  BINDING ARBITRATION
                </h3>
                <ol className="terms-list">
                  <li className="regular">
                    <span className="bold">Applicability of Arbitration Agreement for USA Users.</span> All claims and disputes between users from the USA and Company, arising out of or relating to this Agreement or the use of the Services that cannot be
                    resolved in small claims court will be resolved by binding arbitration on an individual basis ("<span className="bold">Disputes</span>"), except that you and Company are not required to arbitrate any dispute for enforcement or
                    infringement of either party's Intellectual Property Rights ("<span className="bold">Excluded Disputes</span>"). Any and all Disputes relating to, arising out of, or in any way in connection with your rights of privacy and publicity are
                    not Excluded Disputes.
                  </li>

                  <li className="regular">
                    <span className="bold">Arbitration Rules.</span> The Federal Arbitration Act governs the interpretation and enforcement of this dispute-resolution provision. Arbitration will be initiated through the American Arbitration Association
                    ("<span className="bold">AAA</span>"). If the AAA is not available to arbitrate, the parties will select an alternative arbitral forum. The rules of the arbitral forum will govern all aspects of this arbitration, except to the extent
                    those rules conflict with this Agreement. The AAA Consumer Arbitration Rules governing the arbitration are available online at www.adr.org or by calling the AAA at 1-800-778-7879. The arbitration will be conducted by a single
                    neutral arbitrator. Any Disputes where the total amount sought is less than $10,000 may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief. For Disputes where the total amount
                    sought is $10,000 or more, the right to a hearing will be determined by the arbitral forum's rules. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
                  </li>

                  <li className="regular">
                    <span className="bold">Additional Rules for Non-appearance Arbitration.</span> If non-appearance arbitration is elected, the arbitration will be conducted by telephone, online, written submissions, or any combination of the three; the
                    specific manner will be chosen by the party initiating the arbitration. The arbitration will not involve any personal appearance by the parties or witnesses unless the parties mutually agree otherwise.
                  </li>

                  <li className="regular">
                    <span className="bold">Authority of the Arbitrator.</span> The arbitrator will decide the jurisdiction of the arbitrator and the rights and liabilities, if any, of you and Company. The dispute will not be consolidated with any other
                    matters or joined with any other cases or parties. The arbitrator will have the authority to grant motions dispositive of all or part of any claim or dispute. The arbitrator will have the authority to award monetary damages and to
                    grant any non-monetary remedy or relief available to an individual under applicable law, the arbitral forum's rules, and this Agreement. The arbitrator will issue a written award and statement of decision describing the essential
                    findings and conclusions on which the award is based, including the calculation of any damages awarded. The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have. The
                    award of the arbitrator is final and binding upon you and Company.
                  </li>

                  <li className="regular">
                    <span className="bold">Waiver of Jury Trial.</span> YOU AND COMPANY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, OTHER THAN IN RESPECT OF EXCLUDED DISPUTES. You and Company
                    are instead electing to have claims and disputes resolved by arbitration. In any litigation between you and Company over whether to vacate or enforce an arbitration award, YOU AND COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, and elect
                    instead to have the dispute be resolved by a judge.
                  </li>

                  <li className="regular">
                    <span className="bold">Waiver of Class or Consolidated Actions.</span> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS. CLAIMS OF
                    MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER, MARKETING AFFILIATE OR USER. If, however, this waiver of class or consolidated actions is deemed
                    invalid or unenforceable, neither you nor we are entitled to arbitration; instead all claims and disputes will be resolved in a court as set forth in Section 11.12 below.
                  </li>

                  <li className="regular">
                    <span className="bold">Confidentiality.</span> No part of the procedures will be open to the public or the media. All evidence discovered or submitted at the hearing is confidential and may not be disclosed, except by written agreement
                    of the parties, pursuant to court order, or unless required by law. Notwithstanding the foregoing, no party will be prevented from submitting to a court of law any information needed to enforce this arbitration agreement, to enforce
                    an arbitration award, or to seek injunctive or equitable relief.
                  </li>

                  <li className="regular">
                    <span className="bold">Right to Waive.</span> Any rights and limitations set forth in this arbitration agreement may be waived by the party against whom the claim is asserted. Such waiver will not waive or affect any other portion of
                    this arbitration agreement.
                  </li>

                  <li className="regular">
                    <span className="bold">Opt-out.</span> You may opt out of this arbitration agreement. If you do so, neither you nor Company can force the other to arbitrate. To opt out, you must notify Company in writing no later than thirty (30) days
                    after first becoming subject to this Agreement. Your notice must include your name and address and the email address and phone number and an unequivocal statement that you want to opt-out of this arbitration agreement. You must
                    submit a request at the following email address: <Link className="regular" to='#'
                    onClick={(e) => {
                        window.location.href = 'mailto:support@beautiful-essentials.app';
                        e.preventDefault();
                    }} target="_blank">support@beautiful-essentials.app</Link> AND title the email: “Opt-Out of Arbitration Agreement”.
                  </li>

                  <li className="regular">
                    <span className="bold">Small Claims Court.</span> Notwithstanding the foregoing, either you or Company may bring an individual action in small claims court.
                  </li>

                  <li className="regular">
                    <span className="bold">Arbitration Agreement Survival.</span> This arbitration agreement will survive the termination of this Agreement and your relationship with Company.
                  </li>

                  <li className="regular">
                    <span className="bold">Venue for Excluded Disputes.</span> Excluded Disputes shall be governed by and construed exclusively in accordance with the laws of the State of Israel, without regard to the principles of conflict of law therein.
                    The parties consent to the exclusive jurisdiction of the competent courts of Tel Aviv-Jaffa, Israel in respect of Excluded Disputes and hereby submit themselves to the exclusive jurisdiction of these courts.
                  </li>

                  <li className="regular">
                    <span className="bold">U.N. Convention.</span> The application of the United Nations Convention of Contracts for the International Sale of Goods or other international laws is expressly excluded, whether the claim is in arbitration or
                    at court.
                  </li>
                </ol>
              </li>
              <li>
                <p className="header-2 bold">
                  General
                </p>
                <p className="regular">
                  (i) Excluded Disputes and disputes with non-USA users shall be governed by and construed in accordance with the laws of Israel, without regard to the principles of conflict of law therein. The parties consent to the exclusive
                  jurisdiction of the courts of Tel-Aviv in respect of Excluded Disputes and disputes with non-USA users and the parties hereby submit themselves to the exclusive jurisdiction of these courts. The application of the United Nations
                  Convention of Contracts for the International Sale of Goods or other international laws is expressly excluded, whether the claim is in arbitration or at court; (ii) if any provision of these Terms is held to be unenforceable, such
                  provision shall be reformed only to the extent necessary to make it enforceable and if such provision is determined to be invalid or unenforceable nonetheless, the provision shall be deemed to be severable from the remainder of these
                  Terms and shall not cause the invalidity or unenforceability of the remainder of these Terms; (iii) You acknowledge and agree that Company has the right, at any time and for any reason, to redesign or modify the Services and
                  presentation of Your Content and other elements of the Services or any part thereof; (iv) these Terms are the entire agreement between you and Company regarding the subject matter herein and these Terms shall not be modified except by
                  a written instrument executed by both parties; (v) Company may assign these Terms, in whole or in part, in its sole discretion. You are not entitled to assign or otherwise transfer the Terms, or any of your rights or obligations
                  hereunder, to any third party without the prior written consent of Company. Any unauthorized assignment will be void and of no force or effect; (vi) no provisions of these Terms are intended or shall be construed to confer upon or
                  give to any person or entity other than you and Company any rights, remedies or other benefits under or by reason of these Terms; (vii) Company's failure to enforce any rights granted hereunder or to take action against you in the
                  event of any breach hereunder shall not be deemed a waiver by Company as to subsequent enforcement of rights or subsequent actions in the event of future breaches; (viii) all waivers must be in writing. Any waiver or failure to
                  enforce any provision of these Terms on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion; (ix) ANY CAUSE OF ACTION INITIATED BY YOU AND ARISING OUT OF OR RELATED TO THE SERVICE
                  MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED AND YOU SHALL BE DEEMED TO WAIVE ANY CLAIM YOU MAY HAVE IN ESPECT THEREOF.
                </p>

                <p className="regular">
                  If you have any further questions or require further clarification, please contact us by sending an e-mail to: <Link className="regular" to='#'
                  onClick={(e) => {
                      window.location.href = 'mailto:support@beautiful-essentials.app';
                      e.preventDefault();
                  }} target="_blank">support@beautiful-essentials.app</Link>
                </p>
              </li>
            </ol>
          </div>
          <div className="regular section updated-on">
            Last updated: July 27, 2023
          </div>
        </div>
      </div>
    <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/bluebird/3.5.0/bluebird.core.js"></script>
  </div>
  );
}

export default TermsPage;
