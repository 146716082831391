import { Link } from 'react-router-dom';
import '../App.css';

function PrivacyPage() {
  return (
    <div className="privacy layout-column center-center">
      <div className="privacy-terms">
      <Link to='https://beautiful-essentials-cloud-front-bucket.s3.amazonaws.com/landing_page/documents/Privacy+Policy.pdf' target="_blank" className="pdf-link pink bold layout-row">Download pdf</Link>
      <div className="main-title">
        <h1 className="header-2 bold">BEAUTIFUL ESSENTIALS PRIVACY POLICY ("PRIVACY POLICY")</h1>
      </div>
      <div className="page-body">
        <div className="section">
          <p className="header-2">
            <b>IMPORTANT:</b> BY USING PHOTOMYNE LTD.'S ("<span className="bold">COMPANY</span>" OR "<span className="bold">WE</span>") BEAUTIFUL ESSENTIALS SERVICES (COLLECTIVELY, THE "<span className="bold">SERVICES</span>"), YOU ("<span className="bold">YOU</span>")
            CONSENT TO THE TERMS AND CONDITIONS OF THIS PRIVACY POLICY AND CONSENT THAT ALL PII (DEFINED BELOW) THAT YOU SUBMIT OR THAT IS PROCESSED OR COLLECTED THROUGH OR IN CONNECTION WITH THE SERVICES PROVIDED TO YOU WILL BE PROCESSED BY THE
            COMPANY AND ITS AFFILIATES IN THE MANNER AND FOR THE PURPOSES DESCRIBED IN THE FOLLOWING PRIVACY POLICY.
          </p>
          <p className="header-2">
            YOU ARE NOT LEGALLY REQUIRED TO PROVIDE US WITH PII, HOWEVER, USE OF THE SERVICES REQUIRES THAT YOU PROVIDE PII. IF YOU CHOOSE TO WITHHOLD ANY PII REQUIRED IN RESPECT THEREOF, IT WILL NOT BE POSSIBLE FOR YOU TO USE THE SERVICES. IF YOU DO
            NOT AGREE TO THE TERMS AND CONDITIONS SET FORTH HEREIN PLEASE DO NOT USE THE SERVICES.
          </p>
          <p className="regular">
            “<span className="bold">PII</span>” means any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an
            identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
          </p>
          <p className="regular">
            We recognize that privacy is important. This Privacy Policy applies to all of the services, information, tools, features and functionality available as part of the Services offered by the Company or its subsidiaries or affiliated
            companies and covers how PII that the Company collects and receives, including in respect of the Services, is treated. If you have any questions about this Privacy Policy, please feel free to contact us at: <Link className="regular" to='#'
            onClick={(e) => {
                window.location.href = 'mailto:support@beautiful-essentials.app';
                e.preventDefault();
            }} target="_blank">support@beautiful-essentials.app</Link>
          </p>
        </div>
        <div className="section table-contents">
          <p className="header-2">
            POLICY TOPICTS:
          </p>
          <ol>
            <li>
              <a href='/privacy-policy#InformationWeCollect' className="regular">Information We Collect and How We Use It.</a>
            </li>
            <li>
              <a href='/privacy-policy#Links' className="regular">Links.</a>
            </li>
            <li>
              <a href='/privacy-policy#Minors' className="regular">Minors.</a>
            </li>
            <li>
              <a href='/privacy-policy#InformationSharing' className="regular">Information Sharing.</a>
            </li>
            <li>
              <a href='/privacy-policy#DataSecurity' className="regular">Data Security.</a>
            </li>
            <li>
              <a href='/privacy-policy#DataIntegrity' className="regular">Data Integrity.</a>
            </li>
            <li>
              <a href='/privacy-policy#Rights' className="regular">Rights of Data Subjects.</a>
            </li>
            <li>
              <a href='/privacy-policy#Enforcement' className="regular">Enforcement.</a>
            </li>
            <li>
              <a href='/privacy-policy#Changes' className="regular">Changes to This Privacy Policy.</a>
            </li>
            <li>
              <a href='/privacy-policy#Legal' className="regular">Legal Justification and Consent to Processing.</a>
            </li>
            <li>
              <a href='/privacy-policy#Questions' className="regular">Questions.</a>
            </li>
          </ol>
        </div>
        <div className="section">
          <ol className="terms-list">
            <li id="InformationWeCollect">
              <h3 className="header-2 bold">
                Information We Collect and How We Use It.
              </h3>
              <ol className="terms-list">
                <li className="regular">
                  <span className="bold">PII We Collect</span>
                  <p className="regular">
                    We collect PII for the purposes of providing our products and services to you and for related business purposes disclosed in this Privacy Policy.
                    The types of PII relating to you that we may collect, and the purposes for which we process this data, depends on the nature of your interaction with us.
                  </p>
                  <p className="regular">
                    In particular, we may have collected the following categories of personal information:
                    <br></br>
                    <ol className="terms-list">
                      <li className="regular">
                        <span className="bold">Identifiers:</span> Name, email address, account name, unique personal identifier, online identifier, Internet Protocol address, IDFA, AAID, GAID, or other similar identifiers, and Materials you scan or upload to the Services - if you provide such information.
                        Materials may include: Photos.
                      </li>
                      <li className="regular">
                        <span className="bold">Personal information described in the California Consumer Records Act, Section 1798.80, subdivision (e).:</span> Your name.
                      </li>
                      <li className="regular">
                        <span className="bold">Commercial Information:</span> Records of services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies, user communications, feedback and recommendations.
                      </li>
                      <li className="regular">
                        <span className="bold">Internet or other similar network activity:</span> Information on a user's interaction with a website or application, any actions you make on the Services; browsing history, search history, interaction with hyperlinks, widgets and other features of the Services, and device information (such as operating system and its versions, device model, operating system settings, unique device identifiers, crash data, software and hardware attributes), cookies and similar technologies.
                      </li>
                      <li className="regular">
                        <span className="bold">Geolocation data:</span> Approximate geolocation data, time zone, information about your mobile service provider and language settings. We do not collect precise geolocation.
                      </li>
                      <li className="regular">
                        <span className="bold">Inferences drawn from other personal information.:</span> Information to create a profile about a consumer reflecting a consumer's preferences or trends.
                      </li>
                    </ol>
                  </p>
                  <p className="regular">
                    <b>NOTE:</b> You may choose to use the Services to scan documents, which may include other categories of information than identified above or other types of data, including sensitive data.
                    We have no way to prevent you from doing so and if you decide to scan such documents, we do not use them or analyze them.
                    If these documents include information in any of the categories above, we have no way to know that this is the case.
                    We urge you NOT to scan documents in general and specifically those which might include personal information.
                  </p>
                </li>
                <li className="regular">
                  <span className="bold">Categories of Sources from Which We Collect PII</span>
                  <p className="regular">We obtain the categories of PII listed above from the following categories of sources:</p>
                  <ul>
                    <li>
                      Directly from you or your representatives. For example, information that our customers provide to us related to a transaction or material that our customers upload to the apps.
                    </li>
                    <li>
                      Operating systems and platforms. For example, we collect information about your device's interaction with our website and app.
                    </li>
                    <li>
                      Our vendors. We sometimes supplement the information that you provide with information that is received from third parties (See further information below under Section 4 below, Information Sharing).
                      This, in order to provide and improve part of our Services, offer login options, provide emails for marketing and notification purposes.
                      For illustration by way of an example, if you interact with Google and/or any other platform or other social media plug-in in the Services ("App Platforms") in connection with the Services (such as by logging in with your Google account), we may receive information from your respective App Platform account, including your name, profile picture, email address, and any information defined as public pursuant to the policies of such App Platforms.
                      You should check your App Platforms privacy settings to understand and change the information sent to us through the App Platforms.
                      We are not responsible for the privacy practices of such third parties and/or App Platforms.
                      We recommend you to carefully read the terms of use and privacy policies of such third parties and/or App Platforms before using their services and connecting to the Services.
                      This Privacy Policy applies solely to Personal Data collected by us.
                    </li>
                  </ul>
                </li>
                <li className="regular">
                  <span className="bold">Business and Commercial Purposes of Collecting PII From Consumers</span>
                  <p className="regular">We collect PII to:</p>
                  <ul>
                    <li>
                      To enable you to carry out the main purpose for which you requested our services;
                    </li>
                    <li>
                      To improve the user experience, such as providing you with information, support, products or services that you request from us or which may be of interest to you;
                    </li>
                    <li>
                      To improve our Services and to help resolve any problems when you send email or other communication to us;
                    </li>
                    <li>
                      To understand the usage trends and preferences of our users, to improve the way the Services work and look, and to create new features and functionality;
                    </li>
                    <li>
                      To enable internal record keeping;
                    </li>
                    <li>
                      To process your orders and transactions;
                    </li>
                    <li>
                      To fulfill and manage purchases, orders, payments, and returns/exchanges;
                    </li>
                    <li>
                      To communicate to you and your authorized representatives the information on services, experiences, and other special offers we believe may be of genuine interest to you;
                    </li>
                    <li>
                      To market, advertise and assess the effectiveness of our events, promotional campaigns, publications, and services;
                    </li>
                    <li>
                      To market and promote our brand;
                    </li>
                    <li>
                      To operate, troubleshoot, and improve our offering of services;
                    </li>
                    <li>
                      To understand how people use our services, including by generating and analyzing statistics;
                    </li>
                    <li>
                      To operate and improve online services;
                    </li>
                    <li>
                      To personalize online and offline content;
                    </li>
                    <li>
                      To maintain safety in our offices and combat illegal activities;
                    </li>
                    <li>
                      As necessary to ensure network administration and security, such as infrastructure monitoring, identity and credential management, authentication, and access control;
                    </li>
                    <li>
                      For internal administration, data analysis, billing, and detecting, preventing, and responding to actual or potential fraud, illegal activities, or intellectual property infringement;
                    </li>
                    <li>
                      To comply with legal obligations and to enforce our rights;
                    </li>
                    <li>
                      To host, store and otherwise process information needed for business continuity and disaster recovery;
                    </li>
                    <li>
                      To evaluate or conduct a merger, reorganization, dissolution, or other sale or transfer of some or all of our assets, or similar corporate transaction or proceeding, in which personal information held by us is among the assets transferred.
                    </li>
                  </ul>
                </li>
                <li className="regular">
                  <span className="bold">Categories Of PII We Sell Or Share; Categories of Third Parties To Whom Such Information Was Sold Or Shared</span>
                  <p className="regular">
                    We do not sell your personal information in the conventional sense (i.e., for money).
                    Like many companies, however, we use services that help deliver interest-based ads to you and may transfer personal information to business partners for their use.
                    Making information such as online identifiers or browsing activity available to these companies may be considered a “sale” or “sharing” under California law. According to California consumer privacy law, a “sale” includes disclosing, making available or communicating personal information to a third party (except our service providers or contractors) in exchange for monetary or other valuable consideration.
                    “Sharing” includes disclosing, making available or communicating personal information to a third party for cross- context behavioral advertising, whether or not for any monetary or valuable consideration.
                  </p>
                  <p className="regular">
                  We sell or share with third parties the following categories of personal information:
                  <br></br>
                  <div className="table-wrapper">
                    <table className="parties four-columns">
                      <thead>
                        <tr>
                          <td style={{fontWeight: 700}}>Categories of Personal Information Sold or Shared</td>
                          <td style={{fontWeight: 700}}>Categories Of Third Parties To Whom The Information Was Sold Or Shared</td>
                          <td style={{fontWeight: 700}}>Purposes of Sale or Sharing</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Identifiers
                          </td>
                          <td>
                            Advertising companies (like ad servers, advertising agencies, technology vendors, providers of sponsored content, and others), such as Google Analytics, Google AdMob, Microsoft, TikTok, Pinterest, Facebook, Bing
                          </td>
                          <td>
                            Analytics and support, specifically, to track the performance of our websites and applications, to understand our target audiences better and tailor our content strategies accordingly.
                            To generate personalized advertisements or content. our advertising partners build a profile of your interests using your interactions on our website together with personal information you have provided to them elsewhere.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Internet or other electronic network activity information
                          </td>
                          <td>
                            Advertising companies (like ad servers, advertising agencies, technology vendors, providers of sponsored content, and others), such as Google Analytics, Google AdMob, Microsoft, TikTok, Pinterest, Facebook, Bing
                          </td>
                          <td>
                            Analytics and personalized user experience including advertising to identify areas for improvement and make informed decisions to enhance our digital presence, to analyze traffic sources and measure the effectiveness of marketing channels, allowing us to allocate resources effectively.
                            Furthermore, we can assess the performance of our content, identify popular pages, and create more valuable content for our audience.
                            To generate personalized advertisements or content. Our advertising partners build a profile of your interests using your interactions on our website together with personal information you have provided to them elsewhere.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Geolocation data
                          </td>
                          <td>
                            Advertising companies (like ad servers, advertising agencies, technology vendors, providers of sponsored content, and others), such as Google Analytics, Google AdMob, Microsoft, TikTok, Pinterest, Facebook, Bing
                          </td>
                          <td>
                            Analytics and personalized user experience including advertising to identify areas for improvement and make informed decisions to enhance our digital presence, to analyze traffic sources and measure the effectiveness of marketing channels, allowing us to allocate resources effectively.
                            Furthermore, we can assess the performance of our content, identify popular pages, and create more valuable content for our audience.
                            To generate personalized advertisements or content. our advertising partners build a profile of your interests using your interactions on our website together with personal information you have provided to them elsewhere.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </p>
                  <p className="regular">
                    Consumers have the right to opt out of the sale and sharing of personal information. To exercise this right, please follow the instructions on our <Link to='/cookie-policy' target="_blank" className="regular">Cookie Policy</Link>.
                  </p>
                </li>
                <li className="regular">
                  <span className="bold">Aggregate and Analytical Data.</span> In the effort to produce insights regarding use of the Services in order to improve our services and develop and improve automated processes on our Services, we often conduct
                  research on the information submitted. This research is compiled and analyzed on an aggregate basis, and we share this aggregate data with Company’s affiliates, agents and business partners and also disclose aggregated information
                  in order to describe our services to current and prospective business partners or investors. This aggregate information does not identify you or your customers or employees personally.
                </li>
              </ol>
            </li>
            <li id="Links">
              <h3 className="header-2 bold">
                Links.
              </h3>
              <p className="regular">
                Links to other services, sites and applications are provided by the Company as a convenience to our users. The Company is not responsible for the privacy practices or the content of other sites and applications and you visit them at
                your own risk. This Privacy Policy applies solely to PII collected by us.
              </p>
            </li>
            <li id="Minors">
              <h3 className="header-2 bold">
                Minors.
              </h3>
              <p className="regular">
                If any of your data subjects are minors under the age of 18, you must obtain parental consent prior to using our Services in respect of such data subject. If you have reason to believe that a child has provided us with their PII,
                please contact us at the address given above and we will endeavour to delete that PII from our databases unless parental consent was obtained.
              </p>
            </li>
            <li id="InformationSharing">
              <h3 className="header-2 bold">
                Information Sharing.
              </h3>
              <p className="regular">
                As part of providing the Services our affiliates, agents, representatives and service providers will have access to your PII. We require these parties to process such information in compliance with this Privacy Policy and subject to
                security and other appropriate confidentiality safeguards. The Company will also share PII in the following circumstances: (a) as required for providing the Services; (b) for maintenance and improvement of the Services; (c) if we
                become involved in a reorganization, merger, consolidation, acquisition, or any form of sale of some or all of our assets, with any type of entity, whether public, private, foreign or local; and/or (d) to satisfy applicable law or
                prevention of fraud or harm or to enforce applicable agreements and/or their terms, including investigation of potential violations thereof.
              </p>
              <p className="regular">
              Below you can find a list of the categories of our service providers by whom Personal Data may be processed or transferred to and for what purposes.
              </p>
              <div className="table-wrapper">
                  <table className="parties four-columns">
                    <thead>
                      <tr>
                        <td>CATEGORY OF RECIPIENT / SERVICES</td>
                        <td>REASON FOR TRANSFER</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Analytics and Marketing
                        </td>
                        <td>
                          <ul className="terms-list-table">
                            <li>
                              To understand the usage trends and preferences of our users and generate statistical reports.
                            </li>
                            <li>
                              To improve and personalize user experience.
                            </li>
                            <li>
                              To create new features and functionality.
                            </li>
                            <li>
                              To provide us with reports and insights on how to optimize our promotional campaigns and to find people on different platforms that might be interested in our Services.
                            </li>
                            <li>
                              Provide emails for marketing and product related notification purposes.
                            </li>
                            <li>
                              To generate personalized advertisements or content. Our advertising partners build a profile of your interests using your interactions on our website together with personal information you have provided to them elsewhere.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Customer Service and Communications
                        </td>
                        <td>
                          <ul className="terms-list-table">
                            <li>
                              To provide customer service.
                            </li>
                            <li>
                              To reach out to you with our newsletter, surveys and notifications.
                            </li>
                            <li>
                              To obtain customer feedback.
                            </li>
                            <li>
                              Email services.                            
                            </li>
                            <li>
                              In order to make our services more accessible.
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Third Party Service Providers
                        </td>
                        <td>
                          <ul className="terms-list-table">
                            <li>
                              To make available additional features on the Services.
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </li>
            <li id="DataSecurity">
              <h3 className="header-2 bold">
                Data Security.
              </h3>
              <p className="regular">
                We follow generally accepted industry standards to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of PII. However, no method of transmission over the Internet, or method of electronic
                storage, is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your PII, we cannot guarantee its absolute security. We retain your PII only for as long as reasonably necessary for the purposes for
                which it was collected or to comply with any applicable legal or ethical reporting or document retention requirements.
              </p>
            </li>
            <li id="DataIntegrity">
              <h3 className="header-2 bold">
                Data Integrity.
              </h3>
              <p className="regular">
                The Company processes PII only for the purposes for which it was collected and in accordance with this Privacy Policy or any applicable service agreements. We review our data collection, storage and processing practices to ensure that
                we only collect, store and process the PII needed to provide or improve our Services. We take reasonable steps to ensure that the PII we process is accurate, complete, and current, but we depend on our users to update or correct their
                PII whenever necessary. Nothing in this Privacy Policy is interpreted as an obligation to store information, and we may, at our own discretion, delete or avoid from recording and storing any and all information.
              </p>
            </li>
            <li id="Rights">
              <h3 className="header-2 bold">
                Rights of Data Subjects.
              </h3>
              <p className="regular">
                Data subjects have the right to know what PII we collect about them and to ensure that such data is accurate and relevant for the purposes for which we collected it. We allow data subjects the option to access and obtain a copy of
                their PII and to rectify or delete such PII if it is not accurate, complete or updated. However, we may first ask data subjects to provide us certain credentials to permit us to identify their PII.
              </p>
              <p className="regular">
                Data subjects may exercise the above rights by sending a request to <Link className="regular" to='#'
                onClick={(e) => {
                    window.location.href = 'mailto:support@beautiful-essentials.app';
                    e.preventDefault();
                }} target="_blank">support@beautiful-essentials.app</Link> and title the request: “Data Subject Request”.
              </p>
            </li>
            <li id="Enforcement">
              <h3 className="header-2 bold">
                Enforcement.
              </h3>
              <p className="regular">
                The Company regularly reviews its compliance with this Privacy Policy. Please feel free to direct any questions or concerns regarding this Privacy Policy or our treatment of PII by contacting us as provided above. When we receive
                formal written complaints, it is the Company's policy to contact the complaining user regarding his or her concerns. We will cooperate with the appropriate regulatory authorities, including local data protection authorities, to
                resolve any complaints regarding the transfer of PII that cannot be resolved between the Company and an individual.
              </p>
            </li>
            <li id="Changes">
              <h3 className="header-2 bold">
                Changes to This Privacy Policy.
              </h3>
              <p className="regular">
                The Company may update this Privacy Policy. We will notify you about significant changes in the way we treat PII by sending a notice to the email address provided by you or by placing a prominent notice on the Services. We encourage
                you to periodically review this Privacy Policy for the latest information about our privacy practices. Our customers and business partners will be notified of such changes and are responsible to inform their data subjects.
              </p>
            </li>
            <li id="Legal">
              <h3 className="header-2 bold">
                Legal Justification and Consent to Processing.
              </h3>
              <p className="regular">
                By providing any PII to us pursuant to this Privacy Policy, all users, including, without limitation, users in the United States, Israel and member states of the European Union, fully understand and unambiguously consent to this
                Privacy Policy and to the collection and processing of such PII abroad. The server on which the Services are hosted and/or through which the Services are processed may be outside the country from which you access the Services and may
                be outside your country of residence. Some of the uses and disclosures mentioned in this Privacy Policy involve the transfer of your PII to various countries around the world that may have different levels of privacy protection than
                your country and will be transferred outside of the European Economic Area. If there is a transfer of your PII outside the EEA we will, in the absence of an EC Adequacy decision relevant to the destination country or to the transfer,
                seek to rely on appropriate safeguards such as entering into appropriate EC approved standard contractual clauses. By submitting your PII through the Services, you consent, acknowledge, and agree that we will collect, use, transfer,
                and disclose your PII as described in this Privacy Policy.
              </p>
            </li>
            <li id="Questions">
              <h3 className="header-2 bold">
                Questions.
              </h3>
              <p className="regular">
                If you have any questions about this Privacy Policy or concerns about the way we process your PII, and/or if you wish to delete all information regarding your use of the Services, please contact us at: <Link className="regular" to='#'
                onClick={(e) => {
                    window.location.href = 'mailto:support@beautiful-essentials.app';
                    e.preventDefault();
                }} target="_blank"> support@beautiful-essentials.app</Link>
              </p>
            </li>
          </ol>
        </div>
        <div className="regular section updated-on">
          Last updated: July 23, 2024
        </div>
      </div>
    </div>
  </div>
  );
}

export default PrivacyPage;
