import { Link } from 'react-router-dom';
import '../App.css';

function AccessibilityPage() {
  return (
    <div className="privacy layout-column center-center">
      <div className="privacy-terms">
      <div className="main-title">
        <h1 className="header-2 bold">BEAUTIFUL ESSENTIALS ACCESSIBILITY STATEMENT</h1>
      </div>
      <div className="page-body">
        <div className="section">
          <p className="regular">
            This is the Accessibility Statement of Photomyne Ltd.'s (“Company” or
            “We”) Beautiful Essentials Services.
            The Company is committed to promoting digital accessibility for people
            with disabilities. We are continually improving the user experience and
            making best efforts to apply the relevant WCAG 2.1 accessibility standards
            as further detailed below.
          </p>
        </div>
        <div className="section">
          <ol className="terms-list">
            <li>
              <h3 className="header-2 bold">
                Certificate & Conformance Status
              </h3>
              <p className="regular">
                This website conducted a process to implement an automatic accessibility
                tool to conform as closely as possible to the standards set by the WCAG 2.1
                (Web Content Accessibility Guidelines 2.1) at the AA level.
              </p>
            </li>
            <li>
              <h3 className="header-2 bold">
                Measures taken to make this website more accessible
              </h3>
              <p className="regular">
                We use the services of EqualWeb to meet the accessibility needs of our
                users. EqualWeb's automatic accessibility tool implements the following
                measures:
              </p>
              <ul className="terms-list">
                <li className="regular">
                  Option for keyboard navigation.
                </li>
                <li className="regular">
                  Enlargement of the font on the website.
                </li>
                <li className="regular">
                  Blinks Blocking.
                </li>
                <li className="regular">
                  Altered color contrast against dark background.
                </li>
                <li className="regular">
                  Altered color contrast against light background.
                </li>
                <li className="regular">
                  Adaptation for color-blind users.
                </li>
                <li className="regular">
                  Change of font for better readability.
                </li>
                <li className="regular">
                  Enlarged cursor, and change of color to black or white.
                </li>
                <li className="regular">
                  Enlargement of display to ~200%.
                </li>
                <li className="regular">
                  Emphasizing links on the website.
                </li>
                <li className="regular">
                  Emphasizing headings on the website.
                </li>
                <li className="regular">
                  Presentation of alternative text to graphics.
                </li>
                <li className="regular">
                  Accessibility modifications for assistive technologies (e.g. NVDA, JAWS etc.).
                </li>
                <li className="regular">
                  Declaration of accessibility.
                </li>
                <li className="regular">
                  Sending accessibility feedback.
                </li>
              </ul>
            </li>
            <li>
              <h3 className="header-2 bold">
                Compatibility with browsers and assistive technology
              </h3>
              <p className="regular">
                Our goal is to be able to support the widest array of browsers and assistive technologies as possible.
                We have therefore invested efforts to support popular systems with high market share, including Chrome, Safari, Firefox, Edge and Opera.
                We have also addressed JAWS and NVDA assistive technologies for Windows and MAC.
              </p>
            </li>
            <li>
              <h3 className="header-2 bold">
                Technical specifications
              </h3>
              <p className="regular">
                Accessibility of this website relies on the following technologies to work
                with the particular combination of web browser and any assistive
                technologies or plugins installed on your computer:
              </p>
              <ul className="terms-list">
                <li className="regular">
                  HTML
                </li>
                <li className="regular">
                  WAI-ARIA
                </li>
                <li className="regular">
                  CSS
                </li>
                <li className="regular">
                  JavaScript
                </li>
              </ul>
              <p className="regular">
                These technologies are relied upon for conformance with the accessibility standards used.
              </p>
            </li>
            <li>
              <h3 className="header-2 bold">
                Physical location accessibility
              </h3>
              <p className="regular">
                While our company does not provide in-premises services, our company
                considers it very important to provide quality, professional, equitable and
                accessible offices. In our offices you can find:
              </p>
              <ul className="terms-list">
                <li className="regular">
                  Our offices are on the ground floor.
                </li>
                <li className="regular">
                  A wide office door that allows comfortable access.
                </li>
                <li className="regular">
                  Accessible access way (ramp, pathway, Etc.).
                </li>
                <li className="regular">
                  Accessibility waiting areas.
                </li>
              </ul>
            </li>
            <li>
              <h3 className="header-2 bold">
                Contact details of our Compliance Officer
              </h3>
              <p className="regular">
                It is our intention to resolve any complaint to your satisfaction.
                If you have any questions about our accessibility policy, including any requests to
                exercise your legal rights, please contact our compliance officer, Ms. Tal
                Katz, at <Link className="regular" to='#'
                  onClick={(e) => {
                      window.location.href = 'mailto:support@beautiful-essentials.app';
                      e.preventDefault();
                  }} target="_blank">support@beautiful-essentials.app</Link> and mention “Accessibility” in
                  your message’s subject line or call +972-3-944-5639.
              </p>
            </li>
            <li>
              <h3 className="header-2 bold">
                Feedback
              </h3>
              <p className="regular">
                Despite our efforts to make this website accessible to as many disabilities
                as possible, it is possible that some pages or sections on this website have
                human mistakes, for a variety of reasons. We are dedicated to keep
                improving the accessibility features and interface of our website.
              </p>
              <p className="regular">
                Noticed something wrong? You are most welcome to reach out to us at <Link className="regular" to='#'
                  onClick={(e) => {
                      window.location.href = 'mailto:support@beautiful-essentials.app';
                      e.preventDefault();
                  }} target="_blank">support@beautiful-essentials.app</Link>.
              </p>
              <p className="regular">
                You may also reach out to us through the Accessibility Widget by clicking
                on the 'Send Feedback' link. Please note that in such event, the
                information you submit, such as your full name, email address, country,
                mobile phone number, and any text you insert into the 'explain your
                problem' box, may also be shared with EqualWeb, which operates the
                Accessibility Widget. For further information on EqualWeb's privacy
                practices, please read EqualWeb's privacy policy, accessible <Link className="regular" to='https://www.equalweb.com/html5/?_id=8615&did=1116&G=' target="_blank">here</Link>.
              </p>
            </li>
          </ol>
        </div>
        <div className="regular section updated-on">
          Last updated: April 18, 2024
        </div>
      </div>
    </div>
  </div>
  );
}

export default AccessibilityPage;
